@use "@scss" as *;

$px-values: (
  container-border-radius: 12px,
  container-height: 200px,
  container-width: 400px,

  info-container-width: 296px,
  info-container-border-radius: 12px,
  info-margin: 16px,
  info-max-width: 390px,

  text-margin-top: 2px,

  separator-margin: 0 4px,

  distance-icon-margin-top: 2px,

  floor-icon-size: 12px,
);

$rem-values: convert-to-rem($px-values);

.container {
  .content {
    .info {
      max-width: map-get($rem-values, info-max-width);
      margin-right: map-get($rem-values, info-margin);

      .description {
        p {
          margin-top: map-get($rem-values, text-margin-top);
        }
      }

      .address {
        .separator {
          margin: map-get($rem-values, separator-margin);
        }
      }
    }

    .distanceInfo {
      margin-top: map-get($rem-values, distance-icon-margin-top);

      .floor {
        margin: map-get($rem-values, text-margin-top) 0;
      }

      .icon {
        width: map-get($rem-values, floor-icon-size);
        height: map-get($rem-values, floor-icon-size);
      }
    }
  }
}

@mixin buildColors($bg, $color, $subText-color, $accent, $closed) {
  background-color: $bg;

  .content {
    .info {
      .name {
        color: $color;
      }

      .description {
        color: $subText-color;
      }

      .openingTimes {
        span {
          color: $color;
        }

        &.closed {
          span {
            color: $closed;
          }
        }

        &.tempClosed {
          color: $closed;
        }
      }
    }

    .distanceInfo {
      color: $accent;
    }
  }
}

.container {
  display: flex;
  width: 100%;

  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .info {
      z-index: 1;
      .name {
        @include h-font-label-large-prominent;

        &.titleSearch span {
          text-decoration: underline;
        }
      }

      .description {
        .subtitle {
          @include h-font-body-medium;
        }

        .address,
        .openingTimes {
          @include h-font-body-medium;
        }
      }
    }

    .distanceInfo {
      color: $cui-grey-tone-050;
      text-align: right;
      z-index: 1;

      .distance {
        @include h-font-label-large;
      }

      .floor {
        @include h-font-label-medium;
      }

      .icon {
        &.floorUp {
          transform: rotate(-135deg);
        }

        &.floorDown {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.light {
    @include buildColors(
      $cui-white-tone,
      $cui-grey-tone-900,
      $cui-grey-tone-600,
      $cui-blue-tone-600,
      $cui-red-tone-600
    );
  }

  &.dark {
    @include buildColors(
      $cui-grey-tone-800,
      $cui-grey-tone-050,
      $cui-grey-tone-400,
      $cui-blue-tone-200,
      $cui-red-tone-200
    );
  }
}
