@use "@scss" as *;

$modal-body-margin-bottom: 20px;

.modalBody {
  margin-bottom: px-to-rem(modal-body-margin-bottom);
}

.diagnosticsBody {
  white-space: pre-wrap;
  text-align: left;
}

.dark {
  color: $cui-grey-tone-050;
}

.light {
  color: $cui-grey-tone-900;
}
