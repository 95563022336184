@use "@scss" as *;

$px-values: (
  control-right-gap: 30px,
  control-between: 12px,

  search-height-padder: 480px,
  search-results-margin: 0 0 8px,

  toggle-container-margin: 0 auto 16px,

  poi-content-height: 270px,

  single-poi-margin: 20px auto 0,
  single-poi-accessible-margin: 0 auto 20px,

  carousel-margin: 20px 124px 0,
  carousel-accessible-margin: 0 124px 20px,

  search-tags-margin: 20px 112px 0,
  search-tags-accessible-margin: 0 112px 20px,
);

$rem-values: convert-to-rem($px-values);

.container {
  .primaryCompassContainer {
    right: map-get($rem-values, control-right-gap);
    top: map-get($rem-values, control-right-gap);
  }

  .controlTopMargin {
    margin-top: map-get($rem-values, control-between);
  }

  .controlRightMargin {
    margin-right: map-get($rem-values, control-between);
  }

  .searchWrapper {
    .searchHeightPadder {
      height: map-get($rem-values, search-height-padder);
    }

    .searchResultsContainer {
      margin: map-get($rem-values, search-results-margin);
    }
  }

  .toggleContainer {
    > * {
      margin: map-get($rem-values, toggle-container-margin);
    }
  }
}

.poiContainer {
  .carousel {
    margin: map-get($rem-values, carousel-margin);
    display: flex;
    justify-content: center;

    &.accessibleHeight {
      margin: map-get($rem-values, carousel-accessible-margin);
    }
  }

  .singlePoi {
    margin: map-get($rem-values, single-poi-margin);

    &.accessibleHeight {
      margin: map-get($rem-values, single-poi-accessible-margin);
    }
  }
}

.searchTagsContainer {
  margin: map-get($rem-values, search-tags-margin);

  &.accessibleHeight {
    margin: map-get($rem-values, search-tags-accessible-margin);
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .primaryMap {
    position: relative;
    flex: 1;
    z-index: 2;

    .primaryCompassContainer {
      position: absolute;
    }
  }

  .searchWrapper {
    &.accessibleHeight {
      .animatedWrapper {
        transform: translateY(0);
      }
    }

    .animatedWrapper {
      transform: translateY(21%);
    }

    .searchHeightPadder {
      display: flex;
      align-items: flex-end;
    }

    .searchResultsContainer {
      z-index: 3;
    }
  }

  .accessibleHeight.poiContainer {
    order: -1;
  }

  .accessibleHeight.searchTagsContainer {
    order: -1;

    bottom: 100%;
    top: unset;
    margin-bottom: 20px;
  }

  .toggleContainer {
    position: absolute;
    width: 100%;

    > * {
      left: 0;
      right: 0;
      position: absolute;
      bottom: 100%;
      pointer-events: all;
    }
  }

  .hidden {
    visibility: hidden;
    z-index: -1;
  }

  .searchContainer {
    pointer-events: none;
    margin: 0 auto;

    button {
      pointer-events: all;
    }
  }

  .poiContainer {
    width: 100%;

    .carousel,
    .singlePoi {
      pointer-events: all;
      position: absolute;
      left: 0;
      right: 0;

      &.accessibleHeight {
        position: relative;
      }
    }
  }

  .searchTagsContainer {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }
}
