@use "@scss" as *;

$px-values: (
  border-radius: 0 0 24px 24px,
  container-padding: 16px,
  key-gap: 7px,

  backspace-width: 60px,
  spacebar-width: 252px,

  arrow-key-icon-size: 16px,
  backspace-key-icon-size: 24px,

  row-two-margin: 0 38px,
  row-three-margin: 0 64px,
  row-four-margin: 0 116px,
  row-five-margin-left: 168px,

  arrow-key-margin-left: 72px,

  blur: 40px,
);

$rem-values: convert-to-rem($px-values);

.container {
  gap: map-get($rem-values, key-gap);
  padding: map-get($rem-values, container-padding);
  border-radius: map-get($rem-values, border-radius);

  .backdrop {
    border-radius: map-get($rem-values, border-radius);
    backdrop-filter: blur(map-get($rem-values, blur));
  }

  .rowOne {
    .backspaceKey {
      width: map-get($rem-values, backspace-width);
      margin-left: map-get($rem-values, key-gap);

      > svg {
        width: map-get($rem-values, backspace-key-icon-size);
      }
    }
  }

  .rowTwo {
    margin: map-get($rem-values, row-two-margin);
  }

  .rowThree {
    margin: map-get($rem-values, row-three-margin);
  }

  .rowFour {
    margin: map-get($rem-values, row-four-margin);
  }

  .rowFive {
    .columnOne {
      width: map-get($rem-values, spacebar-width);
      margin-left: map-get($rem-values, row-five-margin-left);

      .spaceKey {
        width: map-get($rem-values, spacebar-width);
      }
    }

    .columnTwo {
      margin-left: map-get($rem-values, arrow-key-margin-left);

      .arrowKey {
        > svg {
          width: map-get($rem-values, arrow-key-icon-size);
          height: map-get($rem-values, arrow-key-icon-size);
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  .row,
  .column {
    display: flex;
    justify-content: space-between;
  }

  .rowFive {
    .columnTwo {
      flex: 1;
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &.light {
    .backdrop {
      background: rgba($cui-white-tone, 0.6);
    }
  }

  &.dark {
    .backdrop {
      background: rgba($cui-grey-tone-800, 0.6);
    }
  }
}
