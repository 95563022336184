@use "@scss" as *;
@use "./fonts/fonts.css";

@import-normalize; /* bring in normalize.css styles */

@include scale-for-rem($sm-base-rem, $md-base-rem, $lg-base-rem);

@include scale-for-4k-media-query {
  @include scale-for-rem($sm-base-rem * 2, $md-base-rem * 2, $lg-base-rem * 2);
}

* {
  box-sizing: border-box;
  user-select: none;
  cursor: none !important;
}

*:focus,
*:active {
  outline: none;
}

body {
  margin: 0;
  font-family:
    "IBM Plex Sans",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-control-container .mapboxgl-ctrl:not(.mapboxgl-ctrl-attrib) {
  display: none !important;
}

.mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  font-size: 12px;
  padding: 0 5px;
}

.mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib a {
  pointer-events: none;
}

.mapboxgl-control-container
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib
  .mapboxgl-ctrl-attrib-inner {
  line-height: 1.5;
}

.mapboxgl-popup {
  max-width: none !important;
}

.mapboxgl-popup .mapboxgl-popup-content {
  background: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

.mapboxgl-popup-tip {
  display: none;
}

.asset-information {
  z-index: 1;
}

@include scale-for-4k-media-query {
  .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    font-size: 24px;
    padding: 0 10px;
  }
}
