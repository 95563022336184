@use "@scss" as *;

$px-values: (
  info-margin: 16px,
  info-max-width: 390px,

  icon-container-size: 48px,
  icon-size: 16px,
);

$rem-values: convert-to-rem($px-values);

.container {
  .content {
    .info {
      max-width: map-get($rem-values, info-max-width);
      margin-right: map-get($rem-values, info-margin);
    }

    .iconContainer {
      width: map-get($rem-values, icon-container-size);
      height: map-get($rem-values, icon-container-size);

      .icon {
        width: map-get($rem-values, icon-size);
      }
    }
  }
}

@mixin buildColors($bg, $color, $subText-color, $accent) {
  background-color: $bg;

  .content {
    .info {
      .name {
        color: $color;
      }

      .description {
        color: $subText-color;
      }
    }

    .iconContainer {
      color: $bg;
      background: $subText-color;
    }
  }
}

.container {
  display: flex;
  width: 100%;

  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .info {
      z-index: 1;

      .name {
        @include h-font-label-large-prominent;

        &.titleSearch span {
          text-decoration: underline;
        }
      }

      .description {
        @include h-font-label-medium;
      }
    }

    .iconContainer {
      display: flex;
      align-self: flex-start;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
  }

  &.light {
    @include buildColors(
      $cui-white-tone,
      $cui-grey-tone-900,
      $cui-blue-tone-600,
      $cui-grey-tone-600
    );
  }

  &.dark {
    @include buildColors(
      $cui-grey-tone-800,
      $cui-grey-tone-050,
      $cui-blue-tone-200,
      $cui-grey-tone-400
    );
  }
}
