@use "@scss" as *;

$px-values: (
  size: 56px,
  svg-compass-border-height: 56px,
  svg-compass-text-height: 14px,
);

$rem-values: convert-to-rem($px-values);

.container {
  width: map-get($rem-values, size);
  height: map-get($rem-values, size);

  .compassBorderSvg {
    width: map-get($rem-values, svg-compass-border-height);
  }

  .compassTextSvg {
    height: map-get($rem-values, svg-compass-text-height);
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  box-shadow:
    0px 0px 8px 0px rgba($cui-black-tone, 0.04),
    0px 8px 16px 8px rgba($cui-black-tone, 0.04);

  .compassTextSvg {
    position: absolute;
  }

  &.dark {
    background: $cui-grey-tone-800;
    color: $cui-grey-tone-050;
  }

  &.light {
    background: $cui-white-tone;
    color: $cui-grey-tone-900;
  }
}
