@use "@scss" as *;

$px-values: (
  continue-btn-margin: 24px 0 12px,
  countdown-container-width: 100px,
  countdown-font-size: 44px,
);

$rem-values: convert-to-rem($px-values);

.continueButton {
  margin: map-get($rem-values, continue-btn-margin);
}

.countDownTextContainer {
  width: map-get($rem-values, countdown-container-width);
  height: map-get($rem-values, countdown-container-width);

  .countDownText {
    font-size: map-get($rem-values, countdown-font-size);
  }
}

@mixin buildColors($bg, $color, $svg-color) {
  &.countDownTextContainer {
    background-color: $bg;

    .countDownText {
      color: $color;
    }
  }

  &.recentreButton {
    svg {
      color: $svg-color;
    }
  }
}

.countDownTextContainer {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .countDownText {
    text-align: center;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.44px;
  }
}

.dark {
  @include buildColors(
    rgba($cui-white-tone, 0.16),
    $cui-grey-tone-050,
    $cui-blue-tone-200
  );
}

.light {
  @include buildColors(
    rgba($cui-grey-tone-900, 0.08),
    $cui-grey-tone-900,
    $cui-blue-tone-600
  );
}
