@use "@scss" as *;

$routes-gap: 8px;

.container {
  gap: px-to-rem($routes-gap);
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: end;
}
