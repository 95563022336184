@use "@scss" as *;

$px-values: (
  control-left-gap: 30px,
  control-right-gap: 30px,
  control-between: 12px,

  poi-content-height: 270px,

  control-gap: 40px,
  accessible-control-gap: 32px,
);

$rem-values: convert-to-rem($px-values);

.mapControlsContainer {
  .controlContainer {
    bottom: calc(
      #{map-get($rem-values, control-gap)} + #{map-get(
          $rem-values,
          poi-content-height
        )}
    );

    &.accessibleHeight {
      bottom: map-get($rem-values, accessible-control-gap);
    }
  }
}

.controlLeftContainer {
  left: map-get($rem-values, control-left-gap);
}

.controlRightContainer {
  right: map-get($rem-values, control-right-gap);
}

.searchControlsContainer {
  .searchFeaturesContainer {
    bottom: calc(
      #{map-get($rem-values, control-gap)} + #{map-get(
          $rem-values,
          poi-content-height
        )}
    );

    &.accessibleHeight {
      bottom: map-get($rem-values, accessible-control-gap);
    }
  }
}

.mapControlsContainer {
  position: relative;

  .controlContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    z-index: 1;

    &.controlLeftContainer {
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
}

.searchControlsContainer {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  bottom: 0;
  width: 100%;
  margin: 0 auto;

  .searchFeaturesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
  }
}
