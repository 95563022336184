@use "@scss" as *;

$px-values: (
  container-border-radius: 24px,
  container-height: 260px,
  container-max-width: 856px,
  landscape-container-max-width: 960px,

  qr-code-container-width: 100px,
  qr-code-container-margin: 20px,
  qr-code-padding: 4px,
  qr-code-text-margin: 4px 0 12px 4px,
  qr-code-font-size: 12px,

  line-height-sm: 16px,

  btn-size: 48px,
  btn-position: 12px,

  label-font-size: 12px,
  label-font-height: 16px,
  label-padding: 3.5px 8px 4.5px,
  label-border-radius: 4px,
  label-position: 20px,
);

$rem-values: convert-to-rem($px-values);

.container {
  height: map-get($rem-values, container-height);
  max-width: map-get($rem-values, container-max-width);
  border-radius: map-get($rem-values, container-border-radius);

  &.landscape {
    max-width: map-get($rem-values, landscape-container-max-width);
  }

  .qrCodeContainer {
    width: map-get($rem-values, qr-code-container-width) +
      px-to-rem((map-get($px-values, qr-code-container-width) * 2));

    .qrCodeContent {
      width: map-get($rem-values, qr-code-container-width);
      margin: map-get($rem-values, qr-code-container-margin);

      p {
        margin: map-get($rem-values, qr-code-text-margin);
        font-size: map-get($rem-values, qr-code-font-size);
        line-height: map-get($rem-values, line-height-sm);
      }

      .qrCode {
        padding: map-get($rem-values, qr-code-padding);
      }
    }

    &.hide {
      width: 0;
    }
  }

  .contentContainer {
    border-radius: map-get($rem-values, container-border-radius);

    .closeBtn {
      width: map-get($rem-values, btn-size);
      height: map-get($rem-values, btn-size);
      right: map-get($rem-values, btn-position);
      top: map-get($rem-values, btn-position);
    }

    .label {
      font-size: map-get($rem-values, label-font-size);
      line-height: map-get($rem-values, label-font-height);
      padding: map-get($rem-values, label-padding);
      border-radius: map-get($rem-values, label-border-radius);
      right: map-get($rem-values, label-position);
      top: map-get($rem-values, label-position);
    }
  }
}

@mixin buildColors($bg, $content-bg, $btn-color, $label-bg) {
  &.container {
    background: $bg;

    .qrCodeContainer {
      color: $btn-color;
    }

    .contentContainer {
      background: $content-bg;

      .closeBtn {
        color: $btn-color;
        background: $content-bg;
      }

      .label {
        color: $bg;
        background: $label-bg;
      }
    }
  }
}

.light {
  @include buildColors(
    $cui-grey-tone-050,
    $cui-white-tone,
    $cui-grey-tone-900,
    $cui-blue-tone-600
  );

  .contentContainer {
    box-shadow:
      0px 0px 4px 0px rgba($cui-black-tone, 0.08),
      0px 4px 12px 4px rgba($cui-black-tone, 0.04);
  }
}

.dark {
  @include buildColors(
    #2f2f2f,
    $cui-grey-tone-800,
    $cui-grey-tone-050,
    $cui-blue-tone-200
  );
}

.container {
  display: flex;
  justify-content: space-between;
  box-shadow:
    0px 0px 8px 0px rgba($cui-black-tone, 0.04),
    0px 8px 16px 8px rgba($cui-black-tone, 0.04);

  p {
    margin: 0;
  }

  .qrCodeContainer {
    transition: width 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .qrCodeContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @include h-font-label-small;

      .qrCode {
        background: $cui-white-tone;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .contentContainer {
    position: relative;
    display: flex;
    flex: 1;

    .closeBtn {
      position: absolute;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
    }

    .label {
      @include h-font-label-small;
      position: absolute;
      text-transform: uppercase;
    }
  }
}
