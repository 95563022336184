@use "@scss" as *;

$px-values: (
  header-padding: 8px 16px,
  header-border-radius: 12px 12px 0 0,

  departures-list-padding: 12px 16px,
  departures-list-border-radius: 0 0 12px 12px,
  departures-list-gap: 12px,

  destination-padding-bottom: 12px,

  destination-platform-margin-top: 4px,
  future-departures-margin-top: 2px,
);

$rem-values: convert-to-rem($px-values);

.container {
  .header {
    padding: map-get($rem-values, header-padding);
    border-radius: map-get($rem-values, header-border-radius);
  }

  .departuresList {
    padding: map-get($rem-values, departures-list-padding);
    border-radius: map-get($rem-values, departures-list-border-radius);
    gap: map-get($rem-values, departures-list-gap);

    .destination {
      padding-bottom: map-get($rem-values, destination-padding-bottom);

      .destinationInfo {
        .destinationPlatform {
          margin-top: map-get($rem-values, destination-platform-margin-top);
        }
      }

      .departureTimes {
        .futureDepartures {
          margin-top: map-get($rem-values, future-departures-margin-top);
        }
      }
    }
  }
}

@mixin buildColors($overlay, $heading, $border, $times) {
  &.container {
    .departuresList {
      background: $overlay;

      .noUpcomingDepartures {
        color: $times;
      }

      .destination {
        border-bottom: 1px solid $border;

        .nextDeparture,
        .destinationName {
          color: $heading;
        }

        .futureDepartures {
          color: $times;
        }
      }
    }
  }
}

.dark {
  @include buildColors(
    rgba($cui-white-tone, 0.08),
    $cui-grey-tone-050,
    $cui-grey-tone-700,
    $cui-grey-tone-400
  );
}

.light {
  @include buildColors(
    rgba($cui-grey-tone-900, 0.04),
    $cui-grey-tone-900,
    $cui-grey-tone-200,
    $cui-grey-tone-600
  );
}

.container {
  display: flex;
  flex-direction: column;

  .header {
    @include h-font-label-large-prominent;
  }

  .departuresList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin: 0;
    list-style-type: none;

    .noUpcomingDepartures {
      @include h-font-label-large-prominent;
    }

    .destination {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      .destinationInfo {
        display: flex;
        flex-direction: column;

        .destinationName {
          @include h-font-label-large;
        }

        .destinationPlatform {
          @include h-font-label-medium;
        }
      }

      .departureTimes {
        display: flex;
        flex-direction: column;
        text-align: right;

        .nextDeparture {
          @include h-font-label-large;
          white-space: nowrap;
        }

        .futureDepartures {
          @include h-font-label-medium;
        }
      }
    }
  }
}
