@use "@scss" as *;

$px-to-rem-values: (
  container-border-radius: 24px,
  margin-left: 8px,
  padding: 10px 21px 10px 15px,

  slider-width: 52px,
  slider-height: 28px,
  slider-toggle-size: 20px,
  slider-toggle-expanded-width: 28px,
  slider-translateX: 22px,
  slider-radius: 25px,
  slider-left-position: 5px,
  slider-bottom-position: 4px,

  svg-width: 9.5px,
);

$rem-values: convert-to-rem($px-to-rem-values);

.container {
  border-radius: map-get($rem-values, container-border-radius);
  padding: map-get($rem-values, padding);

  .textLabel {
    margin-left: map-get($rem-values, margin-left);
  }

  .label {
    width: map-get($rem-values, slider-width);
    height: map-get($rem-values, slider-height);

    .input {
      &:checked + .slider .sliderToggle {
        animation: checked 250ms linear forwards;
      }

      &:not(:checked) + .slider .sliderToggle {
        animation: unchecked 250ms linear forwards;
      }
    }

    .slider {
      border-radius: map-get($rem-values, slider-radius);

      &Toggle {
        height: map-get($rem-values, slider-toggle-size);
        width: map-get($rem-values, slider-toggle-size);
        left: map-get($rem-values, slider-left-position);
        bottom: map-get($rem-values, slider-bottom-position);
        border-radius: map-get($rem-values, slider-radius);

        svg {
          width: map-get($rem-values, svg-width);
        }
      }
    }
  }

  @keyframes checked {
    0% {
      width: map-get($rem-values, slider-toggle-size);
    }
    50% {
      width: map-get($rem-values, slider-toggle-expanded-width);
    }
    100% {
      width: map-get($rem-values, slider-toggle-size);
      transform: translateX(map-get($rem-values, slider-translateX));
    }
  }

  @keyframes unchecked {
    0% {
      width: map-get($rem-values, slider-toggle-size);
      transform: translateX(map-get($rem-values, slider-translateX));
    }
    50% {
      width: map-get($rem-values, slider-toggle-expanded-width);
    }
    100% {
      width: map-get($rem-values, slider-toggle-size);
    }
  }
}

@mixin buildColors(
  $color,
  $bg,
  $slider-bg,
  $active-slider-bg,
  $disabled-slider-bg,
  $disabled-slider
) {
  &.container {
    color: $color;
    background: $bg;

    .input {
      &:checked + .slider {
        background: $active-slider-bg;

        svg {
          color: $active-slider-bg;
        }
      }

      &:disabled + .slider {
        background: $disabled-slider-bg;

        .sliderToggle {
          background: $disabled-slider;

          svg {
            color: $disabled-slider-bg;
          }
        }
      }
    }

    .slider {
      background: $slider-bg;

      &Toggle {
        background: $bg;
      }
    }
  }
}

.light {
  @include buildColors(
    $cui-grey-tone-900,
    $cui-white-tone,
    $cui-grey-tone-300,
    $cui-grey-tone-900,
    $cui-grey-tone-200,
    $cui-grey-tone-400
  );
}

.dark {
  @include buildColors(
    $cui-grey-tone-050,
    $cui-grey-tone-800,
    $cui-grey-tone-500,
    $cui-blue-tone-200,
    $cui-grey-tone-600,
    $cui-grey-tone-500
  );
}

.container {
  @include h-font-label-medium;
  display: flex;
  align-items: center;
  width: max-content;
  box-shadow:
    0px 8px 16px 8px rgba($cui-black-tone, 0.04),
    0px 0px 8px 0px rgba($cui-black-tone, 0.12);

  cursor: pointer;

  .label {
    position: relative;
    display: inline-block;

    .input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider .sliderToggle {
        svg {
          animation: svg 250ms linear;
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      &Toggle {
        position: absolute;

        svg {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
        }
      }
    }
  }
}

@keyframes svg {
  to {
    left: 60%;
  }
}
