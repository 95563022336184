@use "@scss" as *;

$px-values: (
  control-size: 116px,
  control-radius: 2.5px,
  icon-width: 14px,
  icon-height: 8.5px,
  icon-bottom: 20px,
  icon-left: 17px,
);

$rem-values: convert-to-rem($px-values);

.container {
  width: map-get($rem-values, control-size);
  height: map-get($rem-values, control-size);

  .buttonUp,
  .buttonRight,
  .buttonDown,
  .buttonLeft {
    width: map-get($rem-values, control-size);
    height: map-get($rem-values, control-size);
    border-radius: map-get($rem-values, control-radius);

    .chevron {
      width: map-get($rem-values, icon-width);
      height: map-get($rem-values, icon-height);
      bottom: map-get($rem-values, icon-bottom);
      left: map-get($rem-values, icon-left);
    }
  }
}

@mixin buildColors($color, $bg, $active-bg) {
  background: $bg;
  color: $color;
  border: 1px solid $bg;

  .buttonUp,
  .buttonRight,
  .buttonDown,
  .buttonLeft {
    &:active {
      background: $active-bg;
    }
  }
}

.container {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  backdrop-filter: blur(8px);

  .buttonUp,
  .buttonRight,
  .buttonDown,
  .buttonLeft {
    position: absolute;
    top: -50%;
    left: 50%;
    transform-origin: bottom left;
    z-index: 1;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: inherit;

    .chevron {
      position: absolute;
      transform: rotate(45deg);
    }
  }

  .buttonUp {
    transform: rotate(-45deg);
  }

  .buttonRight {
    transform: rotate(45deg);
  }

  .buttonDown {
    transform: rotate(135deg);
  }

  .buttonLeft {
    transform: rotate(-135deg);
  }

  &.dark {
    @include buildColors(
      $cui-grey-tone-050,
      rgba($cui-grey-tone-800, 0.2),
      $cui-grey-tone-800
    );
  }

  &.light {
    box-shadow:
      0px 0px 8px 0px rgba($cui-black-tone, 0.04),
      0px 8px 16px 8px rgba($cui-black-tone, 0.04);

    @include buildColors(
      $cui-grey-tone-900,
      rgba($cui-white-tone, 0.2),
      $cui-white-tone
    );
  }
}
