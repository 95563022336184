@use "@scss" as *;

$px-values: (
  image-border-radius: 8px,
  image-margin-right: 16px,
  image-size: 88px,
  image-border-width: 1px,
);

$rem-values: convert-to-rem($px-values);

.container {
  border-radius: map-get($rem-values, image-border-radius);
  margin-right: map-get($rem-values, image-margin-right);
  min-width: map-get($rem-values, image-size);
  max-width: map-get($rem-values, image-size);
  height: map-get($rem-values, image-size);
  border-width: map-get($rem-values, image-border-width);

  .image {
    width: map-get($rem-values, image-size);
    height: map-get($rem-values, image-size);
  }
}

@mixin buildColors($bg) {
  background: $bg;
  border-color: $bg;
}

.container {
  overflow: hidden;
  border-style: solid;

  .image {
    object-fit: cover;
  }

  &.light {
    @include buildColors($cui-grey-tone-100);
  }

  &.dark {
    @include buildColors($cui-grey-tone-700);
  }
}
