@use "@scss" as *;

$px-values: (
  size: 44px,
  border-radius: 8px,

  font-size: 20px,
  line-height: 30px,
);

$rem-values: convert-to-rem($px-values);

.key {
  border-radius: map-get($rem-values, border-radius);
  height: map-get($rem-values, size);
  width: map-get($rem-values, size);
  font-size: map-get($rem-values, font-size);
  line-height: map-get($rem-values, line-height);

  &.activeKey {
    &::before {
      border-radius: map-get($rem-values, border-radius);
    }
  }
}

@mixin buildColors($color, $bg, $iconButtonBg, $activeBg) {
  color: $color;
  background: $bg;

  &.iconButton {
    background: $iconButtonBg;
  }

  &.activeKey {
    &::before {
      background: $activeBg;
    }
  }
}

.key {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  border: none;
  margin: 0;
  padding: 0;
  user-select: none;
  transition:
    background 200ms ease-in-out,
    color 200ms ease-in-out;
  font-weight: 450;
  letter-spacing: 0.1px;

  > * {
    pointer-events: none;
  }

  .verticalAlign {
    line-height: 100%;
    height: 50%;
  }

  &.activeKey {
    transition: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.light {
    @include buildColors(
      $cui-grey-tone-900,
      $cui-white-tone,
      rgba($cui-grey-tone-800, 0.08),
      rgba($cui-grey-tone-900, 0.04)
    );
  }

  &.dark {
    @include buildColors(
      $cui-grey-tone-050,
      rgba($cui-white-tone, 0.12),
      rgba($cui-grey-tone-800, 0.16),
      rgba($cui-white-tone, 0.12)
    );
  }
}
