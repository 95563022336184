@use "@scss" as *;

$px-values: (
  border-radius: 500px,
  border-width: 2px,
  size: 48px,
  active-floor-size: 36px,
  selected-floor-size: 44px,
  poi-dot-size: 8px,
  poi-right: -7px,
  poi-top: 18px,
  label-font-size: 12px,
  label-line-height: 16px,
  label-padding: 12px 8px 8px,
);

$rem-values: convert-to-rem($px-values);

.container {
  border-radius: map-get($rem-values, border-radius);

  .label {
    font-size: map-get($rem-values, label-font-size);
    line-height: map-get($rem-values, label-line-height);
    padding: map-get($rem-values, label-padding);
  }

  .floorList {
    width: map-get($rem-values, size);

    .listItem {
      height: map-get($rem-values, size);
    }

    .userOnFloor {
      &::before {
        width: map-get($rem-values, active-floor-size);
        height: map-get($rem-values, active-floor-size);
      }
    }

    .poiOnFloor {
      div::after {
        width: map-get($rem-values, poi-dot-size);
        height: map-get($rem-values, poi-dot-size);
        right: map-get($rem-values, poi-right);
        top: map-get($rem-values, poi-top);
        border-width: map-get($rem-values, border-width);
      }
    }

    .active {
      &::after {
        width: map-get($rem-values, selected-floor-size);
        height: map-get($rem-values, selected-floor-size);
      }
    }
  }
}

@mixin buildColors(
  $bg,
  $color,
  $active-text-color,
  $current-floor-color,
  $current-floor-bg,
  $selected-floor-bg,
  $poi-border-color
) {
  background: rgba($bg, 0.6);
  border: 1px solid $bg;

  .label {
    color: $color;
  }

  .floorList {
    color: $color;

    .active {
      &::after {
        background-color: $selected-floor-bg;
      }
    }

    .userOnFloor {
      color: $current-floor-color;

      &::before {
        background: $current-floor-bg;
      }
    }

    .active,
    .userOnFloor {
      color: $active-text-color;
    }
  }

  .poiOnFloor {
    div::after {
      border: solid $poi-border-color;
    }
  }
}

.container {
  backdrop-filter: blur(8px);
  flex-direction: column;
  align-items: center;

  .label {
    font-family: "IBM Plex Sans";
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.005em;
    text-align: center;
  }

  .floorList {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    border-radius: map-get($rem-values, border-radius);

    .listItem {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      @include h-font-title-small;
      position: relative;
    }

    .userOnFloor {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        border-radius: 100%;
        z-index: -1;
      }
    }

    .poiOnFloor {
      div::after {
        content: "";
        position: absolute;
        border-radius: 100%;
        background: #f5642d;
        z-index: 1;
      }
    }

    .active {
      &::after {
        content: "";
        position: absolute;
        border-radius: 100%;
        z-index: -2;
      }
    }
  }

  &.light {
    box-shadow:
      0px 0px 8px 0px rgba($cui-black-tone, 0.04),
      0px 8px 16px 8px rgba($cui-black-tone, 0.04);

    @include buildColors(
      $cui-white-tone,
      $cui-grey-tone-900,
      $cui-white-tone,
      $cui-grey-tone-900,
      $cui-blue-tone-600,
      $cui-grey-tone-900,
      $cui-white-tone
    );
  }

  &.dark {
    @include buildColors(
      $cui-grey-tone-800,
      $cui-grey-tone-050,
      $cui-grey-tone-800,
      $cui-grey-tone-800,
      $cui-blue-tone-200,
      $cui-grey-tone-050,
      $cui-grey-tone-800
    );
  }
}
