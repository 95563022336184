@use "@scss" as *;

$px-values: (
  size: 56px,
  svg-size: 21px,
);

$rem-values: convert-to-rem($px-values);

.container {
  &,
  &::before {
    height: map-get($rem-values, size);
    width: map-get($rem-values, size);
  }

  svg {
    height: map-get($rem-values, svg-size);
    width: map-get($rem-values, svg-size);
  }
}

@mixin buildColors($bg, $color, $active-bg, $found-color) {
  @keyframes pulse {
    50% {
      color: rgba($cui-grey-tone-800, 0.04);
    }
  }

  background: $bg;

  svg {
    color: $color;
  }

  &.searching {
    svg {
      animation: pulse 1s ease-in-out infinite;
    }
  }

  &.found {
    svg {
      color: $found-color;
    }
  }

  &:active {
    background: $active-bg;
  }

  &:disabled {
    cursor: auto;

    svg {
      color: $cui-grey-tone-500;
    }
  }

  &::before {
    background: $bg;
  }
}

.container {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  box-shadow:
    0px 4px 12px 5px rgba(0, 0, 0, 0.05),
    0px 0px 12px 0px rgba(0, 0, 0, 0.15);

  &::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    z-index: -1;
  }

  // Light theme
  &.light {
    @include buildColors(
      $cui-white-tone,
      $cui-grey-tone-900,
      rgba($cui-grey-tone-900, 0.04),
      $cui-blue-tone-600
    );
  }

  // Dark theme
  &.dark {
    @include buildColors(
      $cui-grey-tone-800,
      $cui-grey-tone-050,
      rgba($cui-white-tone, 0.12),
      $cui-blue-tone-200
    );
  }
}
