@use "@scss" as *;
@use "@scss/newScaleFunctions.scss" as sf;

$padding: 32px;
$font-size: 40px;

$icon-size: 36px;
$icon-margin: 0 14.5px 0 0;

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
  padding: $padding;
  z-index: 1;

  .heading {
    font-size: $font-size;
    font-weight: 600;
    margin: 0;
    animation: fadeIn 800ms ease-in-out forwards;
  }

  .subheading {
    font-size: $font-size;
    font-weight: 400;
    margin: 0;
    animation: fadeIn 800ms ease-in-out forwards;
  }

  .time {
    font-size: $font-size;
    margin: 0;
    text-align: right;
    animation: fadeIn 800ms ease-in-out forwards;
  }

  .withIcon {
    display: flex;
    align-items: center;

    .icon {
      height: $icon-size;
      width: $icon-size;
      margin: $icon-margin;
    }

    &::after {
      content: "";
      height: $icon-size;
      width: $icon-size;
      animation: pulse 7s infinite;
      background: $cui-blue-tone-600;
      position: absolute;
      z-index: -1;
      border-radius: 100%;
    }
  }
}

@include scale-for-4k-media-query {
  .container {
    padding: sf.scaleMediumDouble($padding);

    .heading {
      font-size: sf.scaleMediumDouble($font-size);
    }

    .subheading {
      font-size: sf.scaleMediumDouble($font-size);
    }

    .time {
      font-size: sf.scaleMediumDouble($font-size);
    }

    .withIcon {
      .icon {
        height: sf.scaleMediumDouble($icon-size);
        width: sf.scaleMediumDouble($icon-size);
        margin: sf.scaleMediumDouble($icon-margin);
      }

      &::after {
        height: sf.scaleMediumDouble($icon-size);
        width: sf.scaleMediumDouble($icon-size);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  15% {
    opacity: 0.3;
  }

  30% {
    transform: scale(4.5, 4.5);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
