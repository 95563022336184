@use "@scss" as *;

$px-values: (
  container-border-radius: 24px,

  info-max-width: 320px,

  background-shadow-width: 380px,

  content-margin: 20px 40px 20px 20px,

  category-margin-top: 8px,

  text-margin-top: 4px,

  separator-margin: 0 4px,

  distance-border-radius: 8px,
  distance-padding: 8px 12px,
  distance-gap: 8px,

  floor-icon-size: 18px,

  shadow-width: 380px,
);

$rem-values: convert-to-rem($px-values);

.container {
  width: map-get($rem-values, info-max-width);
  margin: map-get($rem-values, content-margin);

  &::before {
    width: map-get($rem-values, background-shadow-width);
    border-top-left-radius: map-get($rem-values, container-border-radius);
    border-bottom-left-radius: map-get($rem-values, container-border-radius);
  }

  .info {
    max-width: map-get($rem-values, info-max-width);

    .description {
      p {
        margin-top: map-get($rem-values, text-margin-top);
      }

      .subtitle {
        margin-top: map-get($rem-values, category-margin-top);
      }
    }

    .address {
      .separator {
        margin: map-get($rem-values, separator-margin);
      }
    }
  }

  .distanceInfo {
    border-radius: map-get($rem-values, distance-border-radius);
    padding: map-get($rem-values, distance-padding);
    gap: map-get($rem-values, distance-gap);

    .icon {
      width: map-get($rem-values, floor-icon-size);
      height: map-get($rem-values, floor-icon-size);
    }
  }
}

@mixin buildColors($color, $accent-bg, $closed, $accent-color) {
  &.container {
    .info {
      .name,
      .description,
      .address {
        color: $color;
      }

      .openingTimes {
        span {
          color: $color;
        }

        &.closed {
          span {
            color: $closed;
          }
        }

        &.tempClosed {
          color: $closed;
        }
      }
    }

    .distanceInfo {
      color: $accent-color;
      background: $accent-bg;

      svg {
        path {
          fill: $accent-color;
        }
      }
    }
  }
}

.light {
  @include buildColors(
    $cui-grey-tone-050,
    $cui-blue-tone-600,
    $cui-red-tone-600,
    $cui-white-tone
  );
}

.dark {
  @include buildColors(
    $cui-grey-tone-050,
    $cui-blue-tone-200,
    $cui-red-tone-200,
    $cui-grey-tone-800
  );
}

.container {
  .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .info {
    z-index: 1;
    .name {
      @include h-font-title-large;
    }

    .description {
      .subtitle {
        @include h-font-label-large;
      }

      .address,
      .openingTimes {
        @include h-font-body-medium;
      }
    }
  }

  .distanceInfo {
    color: $cui-grey-tone-050;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    width: fit-content;
    @include h-font-label-large;
    font-weight: 600;
    align-items: center;
    justify-content: center;

    .icon {
      &.floorDown {
        transform: scale(-1, 1);
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 100%;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba($cui-black-tone, 0.4) 68.42%,
      rgba($cui-black-tone, 0) 100%
    );
  }
}
