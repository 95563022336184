@use "@scss" as *;

$px-values: (
  padding: 11.41px 21px,
  font-size: 18px,
  icon-size: 18px,
  icon-margin: 11.5px,
);

$tiny-px-values: (
  padding: 11.41px * 0.8 21px * 0.8,
  font-size: 18px * 0.8,
  icon-size: 18px * 0.8,
  icon-margin: 11.5px * 0.8,
);

$rem-values: convert-to-rem($px-values);
$tiny-rem-values: convert-to-rem($tiny-px-values);

.button {
  padding: map-get($rem-values, padding);
  font-size: map-get($rem-values, font-size);

  .icon {
    height: map-get($rem-values, icon-size);
    width: map-get($rem-values, icon-size);
  }

  .leftIcon {
    margin-right: map-get($rem-values, icon-margin);
  }

  .rightIcon {
    margin-left: map-get($rem-values, icon-margin);
  }

  &.tiny {
    padding: map-get($tiny-rem-values, padding);
    font-size: map-get($tiny-rem-values, font-size);

    .icon {
      height: map-get($tiny-rem-values, icon-size);
      width: map-get($tiny-rem-values, icon-size);
    }

    .leftIcon {
      margin-right: map-get($tiny-rem-values, icon-margin);
    }

    .rightIcon {
      margin-left: map-get($tiny-rem-values, icon-margin);
    }
  }
}

@mixin common-button-styles {
  &:disabled {
    background: rgba($cui-grey-tone-900, 0.12);
    cursor: auto;
    color: $cui-grey-tone-500;
    pointer-events: none;
  }

  &.rounded {
    border-radius: 100px;
  }

  &.outlined {
    background: transparent;

    &:disabled {
      color: $cui-grey-tone-500;
      cursor: auto;
      pointer-events: none;
      background: transparent;
    }
  }
}

@mixin buildButtonColors($bg, $color, $active, $light: true) {
  background: $bg;
  color: $color;

  &.outlined {
    @if $light {
      border: 2px solid $cui-grey-tone-300;
    } @else {
      border: 2px solid $cui-grey-tone-700;
    }

    color: $bg;

    &:active {
      background: $active;
    }

    &:disabled {
      border: 2px solid $cui-grey-tone-500;
    }
  }
}

.button {
  color: $cui-white-tone;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.18px;
  font-family: "IBM Plex Sans";
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 22px;
  font-weight: 500;

  // Light Theme
  &.light {
    &.blue {
      @include buildButtonColors(
        $cui-blue-tone-600,
        $cui-white-tone,
        rgba($cui-blue-tone-600, 0.08)
      );
    }

    &.black {
      @include buildButtonColors(
        $cui-grey-tone-900,
        $cui-white-tone,
        rgba($cui-grey-tone-900, 0.04)
      );
    }

    &.white {
      @include buildButtonColors(
        $cui-white-tone,
        $cui-grey-tone-800,
        rgba($cui-white-tone, 0.04)
      );
    }

    @include common-button-styles;
  }

  // Dark Theme
  &.dark {
    &.blue {
      @include buildButtonColors(
        $cui-blue-tone-200,
        $cui-grey-tone-800,
        rgba($cui-blue-tone-200, 0.16),
        false
      );
    }

    &.black {
      @include buildButtonColors(
        $cui-grey-tone-800,
        $cui-grey-tone-050,
        rgba($cui-white-tone, 0.08),
        false
      );
    }

    &.white {
      @include buildButtonColors(
        $cui-grey-tone-050,
        $cui-grey-tone-800,
        rgba($cui-white-tone, 0.08),
        false
      );
    }

    @include common-button-styles;
  }
}
