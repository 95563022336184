@use "@scss" as *;

$container-border-radius: 24px;

.container {
  .image {
    border-radius: px-to-rem($container-border-radius);
  }
}

.container {
  display: flex;

  .image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
