@use "@scss" as *;

$px-values: (
  container-border-radius: 24px,
  margin-left: 8.5px,
  padding: 10px 21px 10px 15px,
  icon-size: 20px,
  font-size: 14px,
);

$rem-values: convert-to-rem($px-values);

.container {
  border-radius: map-get($rem-values, container-border-radius);
  padding: map-get($rem-values, padding);
  font-size: map-get($rem-values, font-size);

  .icon {
    $size: map-get($px-values, icon-size);

    width: $size;
    height: $size;

    &.error::before {
      width: calc($size / 2);
      height: calc($size / 2);
      left: calc($size / 4);
      top: calc($size / 4);
    }
  }

  .textLabel {
    margin-left: map-get($rem-values, margin-left);
    font-size: map-get($rem-values, font-size);
  }
}

@mixin buildColors($color, $bg, $check-color, $error-color, $error-bg) {
  &.container {
    color: $color;
    background: $bg;

    .icon {
      &.check {
        svg {
          color: $check-color;
        }
      }

      &.error {
        svg {
          color: $error-color;
        }

        &::before {
          background: $error-bg;
        }
      }
    }
  }
}

.light {
  @include buildColors(
    $cui-grey-tone-900,
    $cui-white-tone,
    $cui-green-tone-600,
    $cui-yellow-tone-600,
    $cui-grey-tone-900
  );
}

.dark {
  @include buildColors(
    $cui-grey-tone-050,
    $cui-grey-tone-800,
    $cui-green-tone-200,
    $cui-yellow-tone-200,
    rgba($cui-grey-tone-800, 0.6)
  );
}

.container {
  @include cui-font-label-medium;
  display: flex;
  align-items: center;
  width: max-content;
  z-index: 1;
  box-shadow:
    0px 8px 16px 8px rgba($cui-black-tone, 0.04),
    0px 0px 8px 0px rgba($cui-black-tone, 0.12);

  .icon {
    border-radius: 100%;
    position: relative;

    &.error::before {
      content: "";
      position: absolute;
      border-radius: 100%;
      z-index: -1;
    }
  }
}
