@use "@scss" as *;

$px-values: (
  modal-border-radius: 16px,
  modal-padding: 24px,
  modal-width: 400px,

  title-font-size: 28px,
  title-margin: 0 0 24px,
);

$rem-values: convert-to-rem($px-values);

.modal {
  border-radius: map-get($rem-values, modal-border-radius);
  padding: map-get($rem-values, modal-padding);
  width: map-get($rem-values, modal-width);

  .title {
    font-size: map-get($rem-values, title-font-size);
    margin: map-get($rem-values, title-margin);
  }
}

.container {
  &.accessibleHeight {
    .modal {
      transform: translateY(0);
    }
  }

  .modal {
    align-items: center;
    box-shadow:
      0px 0px 8px 0px rgba(0, 0, 0, 0.04),
      0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
    text-align: center;
    transform: translateY(50%);

    .title {
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.28px;
    }

    /* Dark theme */
    &.dark {
      background: $cui-grey-tone-800;

      .title {
        color: $cui-grey-tone-050;
      }
    }

    /* Light theme */
    &.light {
      background: $cui-white-tone;

      .title {
        color: $cui-grey-tone-900;
      }
    }
  }
}
