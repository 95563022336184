@use "./variables" as *;

@mixin scale-for-rem($sm, $md, $lg) {
  :root {
    &.small {
      --root-font-size: #{$sm};
    }

    &.medium {
      --root-font-size: #{$md};
    }

    &.large {
      --root-font-size: #{$lg};
    }
  }

  html {
    &.small {
      font-size: $sm;
    }

    &.medium {
      font-size: $md;
    }

    &.large {
      font-size: $lg;
    }
  }
}

@mixin scale-for-4k-media-query {
  @media (min-width: $scale-double-min-width) {
    @content;
  }
}
