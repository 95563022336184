@use "@scss" as *;

$px-values: (
  container-width: 620px,
  container-padding: 20px,
  border-radius: 24px,
  gradient-height: 60px,
  scroll-max-height: 480px,

  search-result-bottom-padding: 16px,

  no-results-title-margin-bottom: 4px,
  no-results-container-margin-bottom: 4px,
  no-results-padding: 20px 20px 24px,
);

$rem-values: convert-to-rem($px-values);

.container {
  border-radius: map-get($rem-values, border-radius);
  width: map-get($rem-values, container-width);

  &::after {
    border-radius: 0 0 map-get($rem-values, border-radius)
      map-get($rem-values, border-radius);
    height: map-get($rem-values, gradient-height);
  }

  .scrollContainer {
    max-height: map-get($rem-values, scroll-max-height);
    padding: map-get($rem-values, container-padding);

    .listContainer {
      .searchResult:not(:last-child) {
        padding-bottom: map-get($rem-values, search-result-bottom-padding);
      }
    }
  }

  .noResultsContainer {
    margin-bottom: map-get($rem-values, no-results-container-margin-bottom);
    padding: map-get($rem-values, no-results-padding);

    .title {
      margin-bottom: map-get($rem-values, no-results-title-margin-bottom);
    }
  }
}

.container {
  box-shadow:
    0px 0px 8px 0px rgba($cui-black-tone, 0.12),
    0px 8px 16px 8px rgba($cui-black-tone, 0.04);
  font-family: "IBM Plex Sans";
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    pointer-events: none;
  }

  .scrollContainer {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .listContainer {
      margin: 0;
      padding: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .noResultsContainer {
    text-align: left;

    .title {
      @include h-font-title-medium;
    }

    .paragraph {
      @include h-font-body-medium;
    }
  }

  &.light {
    background: $cui-white-tone;

    &::after {
      background: linear-gradient(
        180deg,
        rgba($cui-white-tone, 0) 0%,
        rgba($cui-white-tone, 0.7) 100%
      );
    }

    .noResultsContainer {
      .title {
        color: $cui-grey-tone-900;
      }

      .paragraph {
        color: $cui-grey-tone-600;
      }
    }
  }

  &.dark {
    background: $cui-grey-tone-800;

    &::after {
      background: linear-gradient(
        180deg,
        rgba($cui-grey-tone-800, 0) 0%,
        rgba($cui-grey-tone-800, 0.7) 100%
      );
    }

    .noResultsContainer {
      .title {
        color: $cui-grey-tone-050;
      }

      .paragraph {
        color: $cui-grey-tone-400;
      }
    }
  }
}
