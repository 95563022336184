@use "@scss" as *;

$px-values: (
  btn-padding: 16px 109px,
  btn-text-size: 22px,
  btn-svg-size: 24px,
  btn-svg-margin-right: 12px,

  btn-saved-term-padding: 16px 154px 16px 20px,

  clear-btn-margin: 10px 10px 10px 0px,
  clear-btn-top-pos: 9px,

  ani-circle-size: 160px,
  ani-blur: 80px,
);

$rem-values: convert-to-rem($px-values);

.container {
  &.savedTerm {
    .button {
      padding: map-get($rem-values, btn-saved-term-padding);
    }

    .clearButton {
      margin: map-get($rem-values, clear-btn-margin);
    }
  }

  .button {
    padding: map-get($rem-values, btn-padding);

    span.text {
      font-size: map-get($rem-values, btn-text-size);
    }

    .svg {
      margin-right: map-get($rem-values, btn-svg-margin-right);
      width: map-get($rem-values, btn-svg-size);
      height: map-get($rem-values, btn-svg-size);
    }

    .animationContainer {
      .circle {
        width: map-get($rem-values, ani-circle-size);
        height: map-get($rem-values, ani-circle-size);
        filter: blur(map-get($rem-values, ani-blur));

        &.red1 {
          animation: red1 11s linear infinite;
        }

        &.purple1 {
          animation: purple1 11s linear infinite;
        }

        &.purple2 {
          animation: purple2 11s linear infinite;
        }

        &.white1 {
          animation: white1 11s linear infinite;
        }
      }
    }

    @keyframes red1 {
      0%,
      30% {
        top: px-to-rem(-80px);
        left: px-to-rem(-360px);
      }

      46% {
        top: px-to-rem(-104px);
        left: px-to-rem(500px);
        opacity: 0.8;
      }

      47%,
      63% {
        opacity: 0;
      }

      64% {
        opacity: 0.8;
        top: px-to-rem(-52px);
        left: px-to-rem(-280px);
      }

      100% {
        top: px-to-rem(-52px);
        left: px-to-rem(500px);
      }
    }

    @keyframes purple1 {
      0%,
      30% {
        top: 0;
        left: px-to-rem(-280px);
      }

      46% {
        top: px-to-rem(-184px);
        left: px-to-rem(580px);

        opacity: 0.8;
      }

      47%,
      63% {
        opacity: 0;
      }

      64% {
        opacity: 0.8;
        top: px-to-rem(-52px);
        left: px-to-rem(-440px);
      }

      100% {
        top: px-to-rem(-52px);
        left: px-to-rem(500px);
      }
    }

    @keyframes purple2 {
      0%,
      30% {
        opacity: 0.8;
        top: px-to-rem(576px);
        left: px-to-rem(-440px);
      }

      46% {
        top: px-to-rem(-344px);
        left: px-to-rem(500px);
        opacity: 0.8;
      }

      47%,
      100% {
        opacity: 0;
      }
    }

    @keyframes white1 {
      0%,
      30% {
        opacity: 0.8;
        top: px-to-rem(96px);
        left: px-to-rem(-720px);
      }

      46% {
        top: px-to-rem(-304px);
        left: px-to-rem(780px);
        opacity: 0.8;
      }

      47%,
      100% {
        opacity: 0;
      }
    }
  }
}

@mixin buildColors(
  $color,
  $bg,
  $ani-circle-color-1,
  $ani-circle-color-2,
  $ani-circle-color-3
) {
  .button {
    background-color: $bg;
    color: $color;

    .animationContainer {
      .circle {
        &.red1 {
          background: $ani-circle-color-1;
        }

        &.purple1,
        &.purple2 {
          background: $ani-circle-color-2;
        }

        &.white1 {
          background: $ani-circle-color-3;
        }
      }
    }
  }
}

.container {
  position: relative;

  .clearButton {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .button {
    border-radius: 99px;
    box-shadow:
      0px 0px 8px 0px rgba($cui-black-tone, 0.04),
      0px 12px 32px 0px rgba($cui-black-tone, 0.12);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .text {
      @include cui-font-label-large;
      z-index: 1;
    }

    .animationContainer {
      width: 100%;
      height: 100%;
      position: absolute;

      &.interacted {
        animation: gradualBlur 3s forwards;
      }

      .circle {
        position: absolute;
        opacity: 0.4;
        border-radius: 100%;
      }
    }
  }

  &.light {
    @include buildColors(
      $cui-grey-tone-900,
      $cui-white-tone,
      $cui-red-tone-600,
      $cui-purple-tone-600,
      $cui-white-tone
    );
  }

  &.dark {
    @include buildColors(
      $cui-grey-tone-050,
      $cui-grey-tone-800,
      $cui-red-tone-200,
      $cui-purple-tone-200,
      $cui-white-tone
    );
  }
}

@keyframes gradualBlur {
  from {
    filter: blur(0px);
  }

  to {
    filter: blur(999px);
  }
}
