@use "@scss" as *;

$inactive-scale: 1;

$px-values: (
  swiper-container: 832px,
  landscape-swiper-container: 960px,
  swiper-slide: 580px,

  inactive-swiper-slide-border-radius: 24px,
  inactive-translate-z: -130px,
  inactive-translate-x: 409px,
  landscape-inactive-translate-x: 339px,
  active-translate-x: 126px,

  swiper-button-size: 48px,
  swiper-button-bottom: 100px,
  swiper-button-x-position: 12px,
  swiper-button-icon-height: 12px,
  swiper-button-icon-width: 7.41px,
);

$rem-values: convert-to-rem($px-values);

.container {
  .swiperContainer {
    width: map-get($rem-values, swiper-container);

    &.landscape {
      width: map-get($rem-values, landscape-swiper-container);
    }

    .swiperSlide {
      width: map-get($rem-values, swiper-slide);

      &.inactive {
        &::after {
          border-radius: map-get(
            $rem-values,
            inactive-swiper-slide-border-radius
          );
        }
      }

      &.activeLeft {
        transform: (
          translateX(-(map-get($rem-values, active-translate-x)))
        ) !important;
      }

      &.activeRight {
        transform: translateX(
          map-get($rem-values, active-translate-x)
        ) !important;
      }

      &.left {
        $translate-x: map-get($rem-values, inactive-translate-x);
        $shared-transform: translateZ(map-get($px-values, inactive-translate-z))
          scale($inactive-scale);

        transform: translateX($translate-x) #{$shared-transform} !important;

        &.landscape {
          $translate-x: map-get($rem-values, landscape-inactive-translate-x);
          transform: translateX($translate-x) #{$shared-transform} !important;
        }
      }

      &.right {
        $translate-x: -(map-get($rem-values, inactive-translate-x));
        $shared-transform: translateZ(map-get($px-values, inactive-translate-z))
          scale($inactive-scale);

        transform: translateX($translate-x) #{$shared-transform} !important;

        &.landscape {
          $translate-x: -(map-get($rem-values, landscape-inactive-translate-x));
          transform: translateX($translate-x) #{$shared-transform} !important;
        }
      }
    }

    .swiperButton {
      height: map-get($rem-values, swiper-button-size);
      width: map-get($rem-values, swiper-button-size);
      bottom: map-get($rem-values, swiper-button-bottom);

      svg {
        height: map-get($rem-values, swiper-button-icon-height);
        width: map-get($rem-values, swiper-button-icon-width);
      }

      &.nextButton {
        right: map-get($rem-values, swiper-button-x-position);
      }

      &.prevButton {
        left: map-get($rem-values, swiper-button-x-position);
      }
    }
  }
}

.container {
  .swiperContainer {
    position: relative;

    .swiperSlide {
      &.inactive {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: rgba($cui-black-tone, 0.4);
        }
      }
    }

    button.swiperButton {
      z-index: 1;
      position: absolute;
      background: $cui-white-tone;

      &:disabled {
        display: none;
      }

      svg {
        position: absolute;
        color: $cui-grey-tone-900;
      }

      &:active {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          background: rgba($cui-black-tone, 0.08);
          border-radius: 100%;
        }
      }
    }
  }
}
