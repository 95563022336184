@use "@scss" as *;

$px-values: (
  qr-code-width: 140px,
  description-font-size: 18px,
  description-margin: 20px 0 44px,
);

$rem-values: convert-to-rem($px-values);

.qrCode {
  width: map-get($rem-values, qr-code-width);
  height: fit-content;
}

.description {
  font-size: map-get($rem-values, description-font-size);
  margin: map-get($rem-values, description-margin);
}

.description {
  color: #212121;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.18px;
}

.dark {
  .qrCode {
    background: #fff;
  }

  .description {
    color: #fff;
  }
}

.light {
  .qrCode {
    color: #212121;
  }

  .description {
    color: #212121;
  }
}
