@use "@scss" as *;

$px-values: (
  container-height: 48px,
  container-width: 96px,
  container-border-radius: 99px,

  btn-border-radius: 99px,
  btn-padding: 10px,
  btn-size: 48px,
  btn-svg-size: 16px,
);

$rem-values: convert-to-rem($px-values);

.container {
  &:not(.vertical) {
    height: map-get($rem-values, container-height);
    width: map-get($rem-values, container-width);
  }

  &.vertical {
    height: map-get($rem-values, container-width);
    width: map-get($rem-values, container-height);
  }

  .button {
    padding: map-get($rem-values, btn-padding);
    height: map-get($rem-values, btn-size);
    width: map-get($rem-values, btn-size);
  }

  .svg {
    width: map-get($rem-values, btn-svg-size);
    height: map-get($rem-values, btn-svg-size);
  }
}

@mixin buildColors($color, $bg, $active-bg) {
  background: $bg;
  border: 1px solid $bg;

  .svg {
    color: $color;
  }

  .button {
    &:active {
      background-color: $active-bg;
    }

    &.disabled {
      .svg {
        color: $cui-grey-tone-500;
      }
    }
  }
}

.container {
  display: flex;
  border-radius: map-get($rem-values, container-border-radius);
  backdrop-filter: blur(8px);

  &.vertical {
    flex-direction: column-reverse;
    align-items: center;
  }

  .button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: map-get($rem-values, btn-border-radius);
  }

  &.dark {
    @include buildColors(
      $cui-grey-tone-050,
      rgba($cui-grey-tone-800, 0.2),
      $cui-grey-tone-800
    );
  }

  &.light {
    box-shadow:
      0px 0px 8px 0px rgba($cui-black-tone, 0.04),
      0px 8px 16px 8px rgba($cui-black-tone, 0.04);

    @include buildColors(
      $cui-grey-tone-900,
      rgba($cui-white-tone, 0.2),
      $cui-white-tone
    );
  }
}
