@use "@scss" as *;

$px-values: (
  info-padding: 20px,
  info-max-width: 300px,

  title-margin-bottom: 8px,

  subtitle-margin-bottom: 4px,

  svg-margin-left: 4px,

  routes-margin-top: 9px,
  routes-min-height: 122px,

  timetable-padding: 20px 72px 20px 0,
  timetable-gap: 12px,

  floor-icon-size: 20px,
);

$rem-values: convert-to-rem($px-values);

.container {
  .infoContainer {
    padding: map-get($rem-values, info-padding);
    min-width: map-get($rem-values, info-max-width);
    width: map-get($rem-values, info-max-width);

    .info {
      .title {
        margin-bottom: map-get($rem-values, title-margin-bottom);
      }

      .details {
        .subtitle {
          margin-bottom: map-get($rem-values, subtitle-margin-bottom);
        }

        .distanceInfo {
          svg {
            margin-left: map-get($rem-values, svg-margin-left);
          }

          .icon {
            width: map-get($rem-values, floor-icon-size);
          }
        }
      }
    }

    .routes {
      margin-top: map-get($rem-values, routes-margin-top);
      min-height: map-get($rem-values, routes-min-height);
    }
  }

  .timetableContainer {
    padding: map-get($rem-values, timetable-padding);
    gap: map-get($rem-values, timetable-gap);
  }
}

@mixin buildColors($color) {
  &.container {
    .info {
      color: $color;

      svg {
        path {
          fill: $color;
        }
      }
    }
  }
}

.light {
  @include buildColors($cui-grey-tone-900);
}

.dark {
  @include buildColors($cui-grey-tone-050);
}

.container {
  display: flex;
  width: 100%;

  .infoContainer {
    box-sizing: border-box;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .info {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title {
        @include h-font-title-large;
      }

      .details {
        @include h-font-label-large;

        .distanceInfo {
          display: flex;
          align-items: flex-start;
          width: fit-content;
          align-items: center;
          justify-content: center;

          .icon {
            &.floorDown {
              transform: scale(-1, 1);
            }
          }
        }
      }
    }
  }

  .timetableContainer {
    flex: 1;
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
