@use "@scss" as *;

$px-values: (
  container-border-radius: 12px,
  container-padding: 12px 16px,
  container-max-width: 344px,
);

$rem-values: convert-to-rem($px-values);

.container {
  border-radius: map-get($rem-values, container-border-radius);
  padding: map-get($rem-values, container-padding);
  max-width: map-get($rem-values, container-max-width);
  width: map-get($rem-values, container-max-width);
}

@mixin buildColors($bg, $text) {
  &.container {
    background: $bg;
    color: $text;
  }
}

.light {
  @include buildColors(rgba($cui-grey-tone-900, 0.04), $cui-grey-tone-600);
}

.dark {
  @include buildColors(rgba($cui-white-tone, 0.08), $cui-grey-tone-400);
}

.container {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  @include h-font-label-large-prominent;
}
