@import "../../scss/legacyScaleFunctions.scss";

$container-width-hd: 400px;
$container-top-spacing-hd: 12px;
$container-border-radius-hd: 3.5px;
$handoff-bottom-spacing-hd: 10px;
$handoff-border-radius-hd: 5px;
$instruction-spacing-hd: 8px;
$instruction-font-size-hd: 9px;
$spacing-hd: 15px;
$cta-font-size-hd: 24px;
$cta-spacing-hd: 12px;
$qr-wrapper-width-hd: 80px;
$link-font-size-hd: 18px;

.container {
  color: #212121;

  .cta {
    font-weight: 600;
    line-height: 1.01em;
    text-align: center;
    animation: fadeIn 800ms ease-in-out forwards;
  }

  .handOffContainer {
    display: flex;
    width: 100%;
    background-color: #fff;

    .qrCodeWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;

      .qrCode {
        width: 100%;
      }
    }

    .linkWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;

      .linkTextContainer {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .link {
          font-weight: 700;
          margin: 0;
        }
      }
    }

    .instructionText,
    .scanInstructions {
      font-weight: 400;
    }
  }

  // Small styles
  &.small {
    border-radius: $container-border-radius-hd;
    padding: $spacing-hd;
    padding-top: $container-top-spacing-hd;
    width: $container-width-hd;

    .cta {
      font-size: $cta-font-size-hd;
      margin: 0 0 $cta-spacing-hd;
    }

    .handOffContainer {
      border-radius: $handoff-border-radius-hd;
      padding: $spacing-hd;
      padding-bottom: $handoff-bottom-spacing-hd;

      .qrCodeWrapper {
        width: $qr-wrapper-width-hd;

        .qrCode {
          height: $qr-wrapper-width-hd;
        }
      }

      .linkWrapper {
        .linkTextContainer {
          .link {
            font-size: $link-font-size-hd;
          }
        }
      }

      .instructionText,
      .scanInstructions {
        font-size: $instruction-font-size-hd;
        margin-top: $instruction-spacing-hd;
      }
    }
  }

  // Medium styles
  &.medium {
    border-radius: scaleMedium($container-border-radius-hd);
    padding: scaleMedium($spacing-hd);
    padding-top: scaleMedium($container-top-spacing-hd);
    width: scaleMedium($container-width-hd);

    .cta {
      font-size: scaleMedium($cta-font-size-hd);
      margin: scaleMedium(0 0 $cta-spacing-hd);
    }

    .handOffContainer {
      border-radius: scaleMedium($handoff-border-radius-hd);
      padding: scaleMedium($spacing-hd);
      padding-bottom: scaleMedium($handoff-bottom-spacing-hd);

      .qrCodeWrapper {
        width: scaleMedium($qr-wrapper-width-hd);

        .qrCode {
          height: scaleMedium($qr-wrapper-width-hd);
        }
      }

      .linkWrapper {
        .linkTextContainer {
          .link {
            font-size: scaleMedium($link-font-size-hd);
          }
        }
      }

      .instructionText,
      .scanInstructions {
        font-size: scaleMedium($instruction-font-size-hd);
        margin-top: scaleMedium($instruction-spacing-hd);
      }
    }
  }

  // Large styles
  &.large {
    border-radius: scaleLarge($container-border-radius-hd);
    padding: scaleLarge($spacing-hd);
    padding-top: scaleLarge($container-top-spacing-hd);
    width: scaleLarge($container-width-hd);

    .cta {
      font-size: scaleLarge($cta-font-size-hd);
      margin: scaleLarge(0 0 $cta-spacing-hd);
    }

    .handOffContainer {
      border-radius: scaleLarge($handoff-border-radius-hd);
      padding: scaleLarge($spacing-hd);
      padding-bottom: scaleLarge($handoff-bottom-spacing-hd);

      .qrCodeWrapper {
        width: scaleLarge($qr-wrapper-width-hd);

        .qrCode {
          height: scaleLarge($qr-wrapper-width-hd);
        }
      }

      .linkWrapper {
        .linkTextContainer {
          .link {
            font-size: scaleLarge($link-font-size-hd);
          }
        }
      }

      .instructionText,
      .scanInstructions {
        font-size: scaleLarge($instruction-font-size-hd);
        margin-top: scaleLarge($instruction-spacing-hd);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
