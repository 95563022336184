@use "@scss" as *;

$px-values: (
  gradient-container-height: 103px,

  footer-buttons-margin-bottom: 9.75px,
  footer-button-gap-margin: 6px,

  logo-size: 6px,
  logo-margin-right: 2px,

  attribution-margin-left: 8px,
  attribution-font-size: 9px,

  attribution-share-container-padding: 0 0 10px,
);

$rem-values: convert-to-rem($px-values);

.container {
  .gradientContainer {
    height: map-get($rem-values, gradient-container-height);
  }

  .contentContainer {
    padding: map-get($rem-values, attribution-share-container-padding);

    .content {
      button {
        margin-bottom: map-get($rem-values, footer-buttons-margin-bottom);

        $x: map-get($rem-values, footer-button-gap-margin);

        &.shareButton.showLowerControls:not(.alwaysLowControls) {
          transform: translateX(calc(-100% - #{$x}));
        }

        &.moveControlsButton {
          left: $x;
        }
      }

      .attribution {
        font-size: map-get($rem-values, attribution-font-size);

        .logo {
          height: map-get($rem-values, logo-size);
          width: map-get($rem-values, logo-size);
          margin-right: map-get($rem-values, logo-margin-right);
        }

        span:last-child {
          margin-left: map-get($rem-values, attribution-margin-left);
        }
      }
    }
  }
}

.container {
  position: absolute;
  width: 100%;
  bottom: 0;
  pointer-events: none;

  .gradientContainer {
    pointer-events: none;
    background-image: linear-gradient(
      rgba($cui-white-tone, 0),
      rgb($cui-white-tone, 0.9)
    );

    button {
      pointer-events: all;
    }
  }

  .contentContainer {
    pointer-events: all;
    background: rgb($cui-white-tone, 0.9);

    .content {
      display: flex;
      flex-direction: column;

      .buttons {
        display: flex;
        flex-direction: row;

        &.showLowerControls {
          transform: translateX(50%);
        }

        .alwaysLowControls {
          transform: translateX(-50%);
        }

        .moveControlsButton {
          position: absolute;
        }
      }

      .attribution {
        font-weight: 450;
        letter-spacing: 0.06px;
        line-height: 12px;
        margin: 0 auto;
      }
    }
  }
}
