@use "@scss" as *;

$px-values: (
  container-border-radius: 12px,
  container-padding: 12px,
  container-gap: 16px,
  container-max-width: 344px,

  landscape-container-max-width: 448px,

  list-item-gap: 12px,
);

$rem-values: convert-to-rem($px-values);

.container {
  border-radius: map-get($rem-values, container-border-radius);
  padding: map-get($rem-values, container-padding);
  gap: map-get($rem-values, container-gap);
  max-width: map-get($rem-values, container-max-width);
  width: map-get($rem-values, container-max-width);

  &.landscape {
    max-width: map-get($rem-values, landscape-container-max-width);
    width: map-get($rem-values, landscape-container-max-width);
  }

  .listItem {
    gap: map-get($rem-values, list-item-gap);
  }
}

@mixin buildColors($bg, $text) {
  &.container {
    background: $bg;
    color: $text;
  }
}

.light {
  @include buildColors(rgba($cui-grey-tone-900, 0.04), $cui-grey-tone-900);
}

.dark {
  @include buildColors(rgba($cui-white-tone, 0.08), $cui-grey-tone-050);
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style-type: none;
  box-sizing: border-box;

  .listItem {
    display: flex;
    align-items: center;
    @include h-font-label-large;

    .destination {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .departureTime {
      white-space: nowrap;
      margin-left: auto;
    }
  }
}
