@use "@scss" as *;

$px-values: (
  container-gap: 12px,
  row-gap: 8px,
);

$rem-values: convert-to-rem($px-values);

.container {
  gap: map-get($rem-values, container-gap);

  .row {
    gap: map-get($rem-values, row-gap);
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    display: flex;
    flex-wrap: nowrap;

    > button {
      pointer-events: all;
    }
  }
}
