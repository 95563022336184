@use "@scss" as *;

$px-values: (
  container-padding: 12px 16px,
  container-border-radius: 12px,
  container-gap: 12px,

  departure-list-item-padding-bottom: 12px,
  departure-list-item-gap: 16px,

  service-details-gap: 2px,

  destination-and-platform-gap: 8px,

  operator-and-time-gap: 8px,
);

$rem-values: convert-to-rem($px-values);

.container {
  padding: map-get($rem-values, container-padding);
  gap: map-get($rem-values, container-gap);
  border-radius: map-get($rem-values, container-border-radius);

  .departureListItem {
    padding-bottom: map-get($rem-values, departure-list-item-padding-bottom);
    gap: map-get($rem-values, departure-list-item-gap);

    .serviceDetails {
      gap: map-get($rem-values, service-details-gap);

      .destinationAndPlatform {
        gap: map-get($rem-values, destination-and-platform-gap);
      }

      .operatorAndTime {
        gap: map-get($rem-values, operator-and-time-gap);
      }
    }
  }
}

@mixin buildColors($bg, $text, $subtitle-text, $border, $late-color) {
  &.container {
    background: $bg;
    color: $text;

    .departureListItem {
      border-bottom: 1px solid $border;

      .operator {
        color: $subtitle-text;
      }

      .time {
        .delayed {
          color: $late-color;
        }
      }
    }
  }
}

.dark {
  @include buildColors(
    rgba($cui-white-tone, 0.08),
    $cui-grey-tone-050,
    $cui-grey-tone-400,
    $cui-grey-tone-700,
    #fff59b
  );
}

.light {
  @include buildColors(
    rgba($cui-grey-tone-900, 0.04),
    $cui-grey-tone-900,
    $cui-grey-tone-600,
    $cui-grey-tone-200,
    #feaa1e
  );
}

.container {
  margin: 0;
  display: flex;
  flex-direction: column;

  .departureListItem {
    display: flex;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .departureTime {
      @include h-font-label-large-prominent;
    }

    .serviceDetails {
      display: flex;
      flex-direction: column;
      flex: 1 0 0;

      .destinationAndPlatform {
        display: flex;
        @include h-font-label-large;

        .departure {
          flex: 1 0 0;
        }
      }

      .operatorAndTime {
        display: flex;
        @include h-font-label-medium;

        .operator {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          flex: 1 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
