@use "@scss" as *;

$px-values: (
  container-size: 620px,
  border-radius: 24px,
  input-padding: 15px 56px 17px,

  clear-icon-size: 14px,
  clear-icon-top: 21px,
  clear-icon-right: 29px,

  search-icon-size: 18px,
  search-icon-top: 19px,
  search-icon-right: 27px,
);

$rem-values: convert-to-rem($px-values);

.container {
  width: map-get($rem-values, container-size);
  border-radius: map-get($rem-values, border-radius);

  .contentWrapper {
    border-radius: map-get($rem-values, border-radius);
  }

  .input {
    padding: map-get($rem-values, input-padding);
    border-radius: map-get($rem-values, border-radius)
      map-get($rem-values, border-radius) 0 0;
  }

  .closeIcon {
    top: map-get($rem-values, clear-icon-top);
    right: map-get($rem-values, clear-icon-right);

    > svg {
      width: map-get($rem-values, clear-icon-size);
      height: map-get($rem-values, clear-icon-size);
    }
  }

  .searchIcon {
    top: map-get($rem-values, search-icon-top);
    left: map-get($rem-values, search-icon-right);

    width: map-get($rem-values, search-icon-size);
    height: map-get($rem-values, search-icon-size);
  }
}

@mixin buildColors(
  $input-color,
  $input-bg,
  $placeholder-color,
  $search-icon-color,
  $close-icon-color
) {
  .input {
    background: $input-bg;
    color: $input-color;

    &::placeholder {
      color: $placeholder-color;
    }
  }

  .closeIcon {
    background: $input-bg;
    color: $input-color;
  }

  .searchIcon {
    color: $search-icon-color;
  }
}

.container {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  box-shadow:
    0px 0px 8px 0px rgba($cui-black-tone, 0.12),
    0px 8px 16px 8px rgba($cui-black-tone, 0.04);

  .input {
    @include h-font-label-huge;
    width: 100%;
    outline: none;
    border: none;
    text-align: center;
    pointer-events: none;
  }

  .closeIcon,
  .searchIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
  }

  &.light {
    @include buildColors(
      $cui-grey-tone-900,
      $cui-white-tone,
      $cui-grey-tone-500,
      $cui-grey-tone-600,
      $cui-grey-tone-900
    );
  }

  &.dark {
    @include buildColors(
      $cui-grey-tone-050,
      $cui-grey-tone-800,
      $cui-grey-tone-500,
      $cui-grey-tone-400,
      $cui-white-tone
    );
  }
}
