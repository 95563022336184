@use "@scss" as *;

$px-values: (
  size: 36px,
  padding: 12px,
  border-radius: 8px,
);

$rem-values: convert-to-rem($px-values);

.container {
  height: map-get($rem-values, size);
  padding: 0 map-get($rem-values, padding);
  border-radius: map-get($rem-values, border-radius);
}

@mixin buildColors($bg, $color, $inactive-bg, $inactive-color) {
  &.container {
    background: $bg;
    color: $color;

    &.inactive {
      background: $inactive-bg;
      color: $inactive-color;
    }
  }
}

.light {
  @include buildColors(
    $cui-grey-tone-900,
    $cui-white-tone,
    rgba($cui-grey-tone-900, 0.08),
    $cui-white-tone
  );
}

.dark {
  @include buildColors(
    $cui-grey-tone-050,
    $cui-grey-tone-800,
    rgba($cui-grey-tone-050, 0.12),
    $cui-grey-tone-050
  );
}

.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include h-font-label-large-prominent;
}
